import { Component, OnInit,Input } from '@angular/core';
import { ApiService } from '../_services/api.service';
  import { FormService } from '../_services/form.service';
  import { ToastService } from '../_component/toast/toast.service';
  import { DataService } from '../_services/data.service';
  import { HttpParams } from '@angular/common/http';
  import { ActivatedRoute, Router } from '@angular/router';
  import * as moment from 'moment/moment';
  import { FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-referal-leaderboard',
  templateUrl: './referal-leaderboard.component.html',
  styleUrls: ['./referal-leaderboard.component.scss']
})
export class ReferalLeaderboardComponent implements OnInit {

  base='deposit-leaderboard';
  checked = true;
  page = 0;
  search = '';
  perPage=0;
  def_inning = 0;
  filter: any = {};
  total=0;
  fields: FormlyFieldConfig[];
  data=[];
  myDate = Date.now();
  prize_breakup=[];
  @Input('competitionId') competitionId = '';

  constructor(private api: ApiService,
    private formService: FormService,
    public dataService: DataService,
    private activatedRoute: ActivatedRoute,
    private toastService: ToastService,
    private router: Router) {
    this.activatedRoute.paramMap.subscribe(value => {
      // if(value.get('competitionId')) {
        this.competitionId = value.get('competitionId');
        this.search = value.has('search') ? value.get('search') : '';
        this.init().then();
        this.dataService.setTitle('Leaderboard');
      // }
    });
  }

  ngOnInit(): void {
  }

  async init() {
    let params = new HttpParams();
    params = params.set('page', (this.page + 1).toString());
    params = params.set('competitionId', this.competitionId);
    params = params.set('per_page', this.perPage.toString());
    // params = params.set('search', this.search.toString());
    // params = params.set('def_inning', this.def_inning);

    for (const f in this.filter) {
      if (this.filter.hasOwnProperty(f)) {
        params = params.set(f, this.filter[f]);
      }
    }

    const value = await this.api.get(this.base, { params }).toPromise();
    // console.log('asdasds');
    //this.initFields();
    if (value.status) {
      const data = value.data;
      this.data = data.users;
      this.total = data.total;
      this.perPage = data.per_page;
      this.page = data.current_page - 1;
    }

  }

  async setParams() {
    //console.log("werrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr");
    Object.assign(this.filter, { page: this.page, perPage: this.perPage ,search:this.search});
    //console.log(this.filter);
    // await this.router.navigate(['/leaderboard-investment/'], { queryParams: params });
    await this.init();

  }

  async exportData() {
    const fields: FormlyFieldConfig[] = [
      // {
      //   key: 'from_date',
      //   type: 'datepicker',
      //   templateOptions: {
      //     label: 'From',
      //     type: 'date',
      //     datepickerOptions: {
      //       max: moment().format('YYYY-MM-DD')
      //     },
      //     required: true,
      //   },
      //   expressionProperties: {
      //     'templateOptions.datepickerOptions.max': 'model.to_date'
      //   }
      // },
      // {
      //   key: 'to_date',
      //   type: 'datepicker',
      //   templateOptions: {
      //     label: 'To',
      //     type: 'date',
      //     datepickerOptions: {
      //       max: moment().format('YYYY-MM-DD')
      //     },
      //     required: true,
      //   },
      //   expressionProperties: {
      //     'templateOptions.datepickerOptions.min': 'model.from_date',
      //   }
      // },
      {
        key: 'type',
        type: 'select',
        templateOptions: {
          label: 'Type',
          options: [
            { value: 'CSV', label: 'CSV' },
            { value: 'XLS', label: 'XLS' }
          ],
          required: true,
        }
      },
    ];


    const newValue = await this.formService.filter(fields, this.filter, {
      width: '300px', reset: {
        user_type: '',
        type: '',
      }
    });

    if (newValue) {
      this.filter = newValue;
      let params = new HttpParams();
      for (const f in this.filter) {
        if (this.filter.hasOwnProperty(f)) {
          if (['from_date', 'to_date'].indexOf(f) > -1) {
            const date = moment(this.filter[f]);
            if (date.isValid()) {
              params = params.set(f, date.format('YYYY-MM-DD'));
            } else {
              await this.toastService.error('Invalid Filter Selection');
            }
          } else {
            params = params.set(f, this.filter[f]);
          }
        }
      }

      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('responseType', 'arrayBuffer');

      const value = await this.api.get('export-deposit-leaderboard', { params, responseType: 'blob' }).toPromise();
      //console.log(value);
      let type = 'application/vba.ms-excel';
      if (this.filter.type === 'CSV') {
        type = 'application/csv';
      }
      this.downLoadFile(value, type);
    }
  }

  downLoadFile(data: any, type: string) {
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    if (type === 'application/vba.ms-excel') {
      a.download = 'investment_detail_export.xlsx';
    } else {
      a.download = 'investment_detail_export.csv';
    }
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
}
