<mat-toolbar>
  <mat-icon>leaderboard</mat-icon>&nbsp;
  <span>Referal Leaderbord</span>
  <span class="fill"></span>
  <input
    (delayedInput)="setParams()"
    [(ngModel)]="search"
    appDelayInput
    class="search-input"
    placeholder="Search..."
    type="search"
  />

  <button
    (click)="exportData()"
    [disabled]="data.length === 0"
    mat-icon-button
    matTooltip="Export Data"
  >
    <mat-icon>file_download</mat-icon>
  </button>
</mat-toolbar>
<div #tt [style.maxHeight]="dataService.getHeight(tt)" class="table-scrollable">
  <table class="table sticky">
    <thead>
      <tr>

        <th>Influencer Name</th>
        <!-- <th>Phone</th> -->
        <th>Total Referal</th>
        <!-- <th>Rank</th> -->
      </tr>
    </thead>
    <!-- <tbody>
      <tr *ngFor="let d of data; let index = index" class="pointer">

        <td>{{ d.email }}</td>
        <td>
            {{ d.phone }}
        </td>
        <td>
          {{ d.total_amount }}
        </td>
        <td>
          {{ d.rank }}
        </td>
      </tr>
    </tbody> -->
  </table>
  <mat-paginator
    (page)="page = $event.pageIndex; perPage = $event.pageSize; init()"
    [length]="total"
    [pageIndex]="page"
    [pageSizeOptions]="[15, 25, 50, 100]"
    [pageSize]="perPage"
  ></mat-paginator>
</div>
