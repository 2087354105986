import { Component } from '@angular/core';
import { AuthService } from './_services/auth.service';
import { FormService } from './_services/form.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { DataService } from './_services/data.service';
import { SubSink } from 'subsink';
import { SocketService } from './_services/socket.service';
import { MessagingService } from './_services/messaging.service';
import { AdminService } from '@services/admin.service';
import { Subscription } from 'rxjs';

interface Menu {
  children: Menu[];
  name: string;
  show?: boolean | false;
  icon: string;
  url: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = this.dataService.title;
  isMobile: boolean;
  message: any;
  sideLinks: Menu[] = [];

  user;

  private subscriptions: Subscription = new Subscription();
  private subs = new SubSink();

  constructor(private authService: AuthService,
    private formService: FormService,
    public dataService: DataService,
    private socketService: SocketService, private adminService: AdminService) {
    // @ts-ignore
    document.title = this.title;

    this.subs.sink = this.dataService.isMobile.subscribe(value => this.isMobile = value);

    this.authService.user.subscribe(value => {
      this.user = value;
    });

  }
  ngOnInit() {
    const subscribedSports = this.adminService.getSubscriptions();
    console.log("subscribedSports", subscribedSports)

    this.subscriptions.add(
      this.adminService.subscriptions$.subscribe(subscribedSports => {
        console.log('Subscribed sports in ngOnInit:', subscribedSports);
        this.buildMenu(subscribedSports);
      })
    );
  }
  private buildMenu(subscribedSports: string[]) {
    const data = [
      {
        name: 'Dashboard',
        url: 'dashboard',
        icon: 'dashboard',
        show: false,
        children: []
      },
      {
        name: 'Admin Manager',
        url: '',
        show: true,
        children: [
          {
            name: 'Module',
            url: 'modules',
            icon: 'category',
            children: []
          },
          {
            name: 'Sub Module',
            url: 'sub-modules',
            icon: 'filter_frames',
            children: []
          },
          {
            name: 'Sub Admin',
            url: 'sub-admin',
            icon: 'emoji_events',
            children: []
          },
          {
            name: 'Admin Log Activity',
            url: 'admin-log-activity',
            icon: 'playlist_add_check',
            children: []
          },
        ],
        icon: 'emoji_events',
      },
      {
        name: 'Users',
        url: 'users',
        icon: 'people',
        show: true,
        children: []
      },
      {
        name: 'Wallet Manager',
        url: 'wallet',
        icon: 'payments',
        children: [],
        show: true,
      },
      {
        name: 'Verification',
        url: 'verification',
        icon: 'verified',
        children: [],
        show: true,
      },
      //  ##################### CRICKET ##################
      {
        name: 'Cricket',
        url: '',
        show: subscribedSports.includes('cricket'),
        children: [
          {
            name: 'Contest Categories',
            url: 'contest-categories',
            icon: 'category',
            children: []
          },
          {
            name: 'Contest Templates',
            url: 'contest-templates',
            icon: 'filter_frames',
            children: []
          },
          {
            name: 'Rank Categories',
            url: 'rank-categories',
            icon: 'emoji_events',
            children: []
          },
          {
            name: 'Private Contests',
            url: 'private-contests',
            icon: 'playlist_add_check',
            children: []
          },
          {
            name: 'Competitions',
            url: 'competitions',
            icon: 'view_agenda',
            children: []
          },
          {
            name: 'Fixtures',
            url: 'fixtures',
            icon: 'class',
            children: []
          },
          {
            name: 'Contests',
            url: 'contests',
            icon: 'playlist_add_check',
            children: []
          },
          {
            name: 'Fantasy Points',
            url: 'fantasy-points',
            icon: 'star',
            children: []
          },
        ],
        icon: 'emoji_events',
      },
      //  ##################### CRICKET ##################

      //  ##################### FOOTBAL ##################
      {
        name: 'Football',
        url: 'ft',
        show: subscribedSports.includes("football"),
        children: [
          {
            name: 'Contest Categories',
            url: 'ft/contest-categories',
            icon: 'category',
            children: []
          },
          {
            name: 'Contest Templates',
            url: 'ft/contest-templates',
            icon: 'filter_frames',
            children: []
          },
          {
            name: 'Rank Categories',
            url: 'ft/rank-categories',
            icon: 'emoji_events',
            children: []
          },
          {
            name: 'Private Contests',
            url: 'ft/private-contests',
            icon: 'playlist_add_check',
            children: []
          },
          {
            name: 'Competitions',
            url: 'ft/competitions',
            icon: 'view_agenda',
            children: []
          },
          {
            name: 'Fixtures',
            url: 'ft/fixtures',
            icon: 'class',
            children: []
          },
          {
            name: 'Contests',
            url: 'ft/contests',
            icon: 'playlist_add_check',
            children: []
          },
          {
            name: 'Fantasy Points',
            url: 'ft/fantasy-points',
            icon: 'star',
            children: []
          },
        ],
        icon: 'emoji_events',
      },
      //  ##################### FOOTBAL ##################

      //  ##################### Kabbadi ##################
      {
        name: 'Kabaddi',
        url: 'kbd/',
        show: subscribedSports.includes("kabaddi"),
        children: [
          {
            name: 'Contest Categories',
            url: 'kbd/contest-categories',
            icon: 'category',
            children: []
          },
          {
            name: 'Contest Templates',
            url: 'kbd/contest-templates',
            icon: 'filter_frames',
            children: []
          },
          {
            name: 'Rank Categories',
            url: 'kbd/rank-categories',
            icon: 'emoji_events',
            children: []
          },
          {
            name: 'Private Contests',
            url: 'kbd/private-contests',
            icon: 'playlist_add_check',
            children: []
          },
          {
            name: 'Competitions',
            url: 'kbd/competitions',
            icon: 'view_agenda',
            children: []
          },
          {
            name: 'Fixtures',
            url: 'kbd/fixtures',
            icon: 'class',
            children: []
          },
          {
            name: 'Contests',
            url: 'kbd/contests',
            icon: 'playlist_add_check',
            children: []
          },
          {
            name: 'Fantasy Points',
            url: 'kbd/fantasy-points',
            icon: 'star',
            children: []
          },
        ],
        icon: 'emoji_events',
      }
      ,    //  ##################### Kabbadi ##################
      {
        name: 'Coupons',
        url: 'coupons',
        icon: 'redeem',
        children: [],
        show: true,
      },
      {
        name: 'Payments',
        url: 'payments',
        icon: 'payments',
        children: [],
        show: true,
      },
      {
        name: 'Withdraw',
        url: 'withdrawals',
        icon: 'payments',
        children: [],
        show: true,
      },
      {
        name: 'TDS',
        url: 'tds',
        icon: 'money_off',
        children: [],
        show: true,
      },
      {
        name: 'Notifications',
        url: 'notifications',
        icon: 'send',
        children: [],
        show: true,
      },
      {
        name: 'Winners',
        url: 'winners',
        icon: 'emoji_events',
        children: [],
        show: true,
      },
      {
        name: 'Settings',
        url: 'settings',
        icon: 'settings',
        children: [],
        show: true,
      },
      {
        name: 'Others',
        url: '',
        show: true,
        children: [
          {
            name: 'States',
            url: 'states',
            icon: 'public',
            children: []
          },
          {
            name: 'Pages',
            url: 'pages',
            icon: 'pages',
            children: []
          },
          {
            name: 'Blogs',
            url: 'blogs',
            icon: 'post_add',
            children: []
          },
          {
            name: 'Banners',
            url: 'banners',
            icon: 'images',
            children: []
          },
          {
            name: 'Faqs',
            url: 'faqs',
            icon: 'help',
            children: []
          },
        ],
        icon: 'emoji_events',
      },
    ]

    this.sideLinks = data.filter((manu) => {
      return manu.show
    })
  }
  async changePassword() {
    const fields: FormlyFieldConfig[] = [{
      validators: {
        validation: [
          { name: 'fieldMatch', options: { errorPath: 'password_confirmation' } },
        ],
      },
      fieldGroup: [
        {
          key: 'current_password',
          type: 'input',
          templateOptions: {
            label: 'Current Password',
            type: 'password',
            required: true
          }
        },
        {
          key: 'password',
          type: 'input',
          templateOptions: {
            label: 'New Password',
            type: 'password',
            required: true
          }
        },
        {
          key: 'password_confirmation',
          type: 'input',
          templateOptions: {
            label: 'Confirm Password',
            type: 'password',
            required: true
          }
        }
      ]
    }];
    await this.formService.show(fields, 'update/password', 'POST', {}, { title: 'Change Password', width: '300px' });
  }

  async logout() {
    await this.authService.logout();
    await this.socketService.disconnect();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
