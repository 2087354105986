<mat-toolbar>
  <mat-icon>redeem</mat-icon>&nbsp;
  <span>Subscription</span>
  <span class="fill"></span>
  <button (click)="add()" mat-icon-button matTooltip="Add Coupon">
    <mat-icon>add</mat-icon>
  </button>
</mat-toolbar>
<div #tt [style.maxHeight]="dataService.getHeight(tt)" class="table-scrollable">

  <table class="table sticky">
    <thead>
      <tr>
        <th>#</th>
        <th>Name</th>
        <th class="text-right">Waiver</th>
        <th class="text-right">Validity</th>
        <th class="text-right">Amount</th>
        <th class="text-right">Previous Amount</th>
        <th class="text-right">Capping Amount</th>
        <th>Features</th>
        <th>Is Active?</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let d of data; let index = index">
        <td>{{ (index + 1) + page * perPage }}</td>
        <td>{{d.name}}</td>
        <td class="text-right">{{d.waiver}}</td>
        <td class="text-right">{{d.validity}}</td>
        <td class="text-right">{{d.amount}}</td>
        <td class="text-right">{{d.previous_amt}}</td>
        <td class="text-right">{{d.capping}}</td>

        <td>
          <ul>
            <li *ngFor="let feature of d?.features?.featuresData">
               {{ feature?.name }}
            </li>
          </ul>
        </td>
       
        <td>{{d.isActive ? 'Yes' : 'No'}}</td>
        <td>
          <button (click)="edit(d)" [disabled]="d.original_id" mat-icon-button matTooltip="Edit">
            <mat-icon>create</mat-icon>
          </button>
          <button (click)="remove(d)" [disabled]="d.contests_count > 0 || d.original_id" color="warn" mat-icon-button
            matTooltip="Remove">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>