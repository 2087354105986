<mat-toolbar>
  <mat-icon>filter_frames</mat-icon>&nbsp;
  <span>Add Contest</span>
  <span class="fill"></span>
</mat-toolbar>
<div class="mat-elevation-z1">
  <div class="padding bg-white">
    <div #tt [style.maxHeight]="dataService.getHeight(tt)" class="table-scrollable">

      <form (ngSubmit)="contestDataSubmit(contestForm.value)" #contestForm="ngForm">
        <h3>Select Inning:</h3>
        <select class="form-control" style="display: block;
          width: 20%;
          padding: 0.375rem 2.25rem 0.375rem 0.75rem;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5;
          background-color: #fff;
          background-size: 16px 12px;
          border: 1px solid #ced4da;
          appearance: none;" name="select_inning" [(ngModel)]="def_inning" (change)="findcontest(iningselect.value)" #iningselect>
          <option *ngFor="let in of innings" [value]="in.id">{{in.name}}</option>
        </select>
        <br>
        <table class="table sticky">
          <tr>
            <td colspan="7">
              <input type="checkbox" [(ngModel)]="masterSelected" name="list_name" (change)="checkUncheckAll()" /> Select/Unselect All
            </td>
          </tr><br>
          <tbody *ngFor="let d of data;" class="pointer">
            <tr *ngIf="cat==null || d.ct_name!=cat">
              <td colspan="7">
                <input type="checkbox" [(ngModel)]="childSelected" name="child_listname[]"
                  (change)="childCheckUncheckAll(d.ct_name)" [checked]="false" /> <b> {{cat}}</b>
              </td>
            </tr>
            <tr *ngIf="cat==null || d.ct_name!=cat ? changeCt(d.ct_name):''">
              <th width="5%">#</th>
              <th>Template Name</th>
              <th>Winning Amount</th>
              <th>Total Teams</th>
              <th>Entry Fee</th>
              <th>Is confirmed?</th>
              <th>Is Dynamic?</th>
            </tr>
    
            <tr>
              <td *ngIf="!valueExists(d.id)">
                <input type="checkbox" value="d.id" [(ngModel)]="d.isSelected" name={{d.id}}>
              </td>
              <td *ngIf="valueExists(d.id)">
                <input type="checkbox" value="d.id" ngModel name={{d.id}} disabled>
              </td>
              <td>{{d.name}}</td>
              <td>{{d.winner_percentage}}</td>
              <td>{{d.total_teams}}</td>
              <td> {{d.entry_fee}}</td>
              <td>{{d.is_confirmed ? 'Yes' : 'No'}}</td>
              <td>{{d.is_dynamic ? 'Yes' : 'No'}}</td>
            </tr>
    
          </tbody>
        </table>
        <!-- <button>Add</button> -->
        <button mat-raised-button color="primary">
          Add
        </button>
      </form>
    </div>
  </div>
</div>

