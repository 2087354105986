<mat-toolbar>
    <mat-icon>redeem</mat-icon>&nbsp;
    <span>GST Details</span>
    <span class="fill"></span>

  
   

  
    <!-- <input [(ngModel)]="search" class="search-input" appDelayInput (delayedInput)="init()" placeholder="Search..."
    type="search">&nbsp; -->
   
  
  
  
  </mat-toolbar>
  <div  class="table-scrollable">
    <table class="table sticky">
      <thead>
        <tr>
          <th>#</th>
          <!-- <th>User ID</th> -->
          <th>User Name </th>
          <th class="">Transaction ID</th>
          <th class="">Subscription Name</th>
  
          <th class="">GST Amount</th>
          <th class="">Payment Mode</th>
          <!-- <th class="">Payment Description</th>
          <th class="">Active Date</th>
          <th class="">Expiry Date</th>
          <th class="">Status</th>
          <th>Status Note</th>
          <th>User Message</th>
          <th>Is Active?</th>
          <th>Auto Renewal</th>
          <th>Action</th> -->
        </tr>
      </thead>
      <tbody>
     
  
          <!-- <td class="">{{ d.subcription_id }}</td>
          <td class="">{{ d.gst_subscription.name }}</td>
          <td class="">{{ d.purchase_amt }}</td>
          <td class="">{{ d.payment_mode }}</td>
          <td class="">{{ d.payment_description }}</td>
          <td class="">{{ d.active_date }}</td>
          <td class="">{{ d.expiry_date }}</td> -->
          <!-- <td class="">{{ d.status }}</td> -->
  
         
           
            
         
  
         
  
  
  
      
  
  
  
  
          
  
  
  
  
      </tbody>
    </table>
    <!-- <mat-paginator (page)="page=$event.pageIndex;perPage=$event.pageSize;init()" [length]="total" [pageIndex]="page"
      [pageSizeOptions]="[15, 25, 50, 100]" [pageSize]="perPage"></mat-paginator> -->
  </div>