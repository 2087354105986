import { Component, OnInit, } from '@angular/core';
import { ApiService } from '../_services/api.service';
import { FormService } from '../_services/form.service';
import { DataService } from '../_services/data.service';
import { HttpParams } from '@angular/common/http';
import { FormlyFieldConfig } from '@ngx-formly/core';
import * as moment from 'moment/moment';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
})
export class SubscriptionComponent implements OnInit {
  base = 'GstSubscription';
  data: any[] = [];
  search = '';
  total = 0;
  page = 0;
  perPage = 0;
  roleid: any;
  accesspage: any;
  slug: any;

  constructor(
    private api: ApiService,
    private formService: FormService,
    public dataService: DataService,

  ) { }

  ngOnInit() {
    console.log('ngOnInit called');
    this.rolecheck();
    this.dataService.setTitle('GstSubscription');
  }

  async rolecheck() {
    const value = await this.api.get('userrole').toPromise();
    if (value.status) {
      const data = value.data;
      this.roleid = data.role_id;
      this.accesspage = data.accesspage;
      this.init();
    }
  }

  async init() {
    this.slug = 'GstSubscription';
    if (
      this.roleid == 1 ||
      (this.roleid == 2 ? (this.accesspage[this.slug] == 1 ? true : false) : true)
    ) {
      let params = new HttpParams();
      params = params.set('page', (this.page + 1).toString());
      params = params.set('per_page', this.perPage.toString());
      params = params.set('search', this.search.toString());
      const value: any = await this.api.get(this.base, { params }).toPromise();

      // this.data = value as any[];
      if (value.status) {
        this.data = value.data as any[];

      }
    } else {
      alert("You can't access this panel");
    }
  }
  async addFeatureField(featureArray: FormlyFieldConfig[]) {
    const newFeatureField: FormlyFieldConfig = {
      key: 'name',
      type: 'input',
      templateOptions: {
        label: 'Feature Name',
        required: true,
      },
    };

    featureArray.push(newFeatureField);
  }

  async add() {
    const fields: FormlyFieldConfig[] = [
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            key: 'name',
            type: 'input',
            className: 'col-12',
            templateOptions: {
              label: 'Name',
              required: true,
            },
          },
          {
            key: 'amount',
            type: 'input',
            className: 'col-12',
            templateOptions: {
              label: 'Amount',
              required: true,
              type: 'number',
              min: 0,
            },
          },
          {
            key: 'waiver',
            type: 'input',
            className: 'col-12',
            templateOptions: {
              label: 'Waiver',
              required: true,
              type: 'number',
              min: 0,
            },
          },
          {
            key: 'previous_amt',
            type: 'input',
            className: 'col-12',
            templateOptions: {
              label: 'Previous Amount',
              required: true,
              type: 'number',
              min: 0,
            },
          },
          {
            key: 'validity',
            type: 'input',
            className: 'col-12',
            templateOptions: {
              label: 'Validity',
              required: true,
              type: 'number',
              min: 0,
            },
          },
          {
            key: 'capping',
            type: 'input',
            className: 'col-12',
            templateOptions: {
              label: 'Capping',
              required: true,
              type: 'number',
              min: 0,
            },
          },
          {
            key: 'isActive',
            type: 'select-search',
            className: 'col-12',
            templateOptions: {
              label: 'Is Active?',
              required: true,
              options: [
                {
                  id: 1,
                  name: 'Yes',
                },
                {
                  id: 0,
                  name: 'No',
                },
              ],
            },
          },

          {
            key: 'features.featuresData',
            type: 'repeat',
            className: 'col-12',
            templateOptions: {
              label: 'Features',
            },
            fieldArray: {
              fieldGroup: [
                {
                  key: 'name',
                  type: 'input',
                  templateOptions: {
                    label: 'Feature Name',
                    required: true,
                  },
                },
              ],
            },
          },

        ]
      },
    ];

    const value: any = await this.formService.show(
      fields,
      this.base,
      'POST',
      {},
      {
        title: 'Create Subscription Plan',
        width: '350px',
      }
    );

    if (value) {
      await this.init();
    }
  }

  async edit(d: any) {
    const fields: FormlyFieldConfig[] = [
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            key: 'name',
            type: 'input',
            className: 'col-12',
            templateOptions: {
              label: 'Name',
              required: true,
            },
          },
          {
            key: 'amount',
            type: 'input',
            className: 'col-12',
            templateOptions: {
              label: 'Amount',
              required: true,
              type: 'number',
              min: 0,
            },
          },
          {
            key: 'waiver',
            type: 'input',
            className: 'col-12',
            templateOptions: {
              label: 'Waiver',
              required: true,
              type: 'number',
              min: 0,
            },
          },
          {
            key: 'previous_amt',
            type: 'input',
            className: 'col-12',
            templateOptions: {
              label: 'Previous Amount',
              required: true,
              type: 'number',
              min: 0,
            },
          },
          {
            key: 'validity',
            type: 'input',
            className: 'col-12',
            templateOptions: {
              label: 'Validity',
              required: true,
              type: 'number',
              min: 0,
            },
          },
          {
            key: 'capping',
            type: 'input',
            className: 'col-12',
            templateOptions: {
              label: 'Capping',
              required: true,
              type: 'number',
              min: 0,
            },
          },
          {
            key: 'isActive',
            type: 'select-search',
            className: 'col-12',
            templateOptions: {
              label: 'Is Active?',
              required: true,
              options: [
                {
                  id: 1,
                  name: 'Yes',
                },
                {
                  id: 0,
                  name: 'No',
                },
              ],
            },
          },
          {
            key: 'features.featuresData',
            type: 'repeat',
            className: 'col-12',
            templateOptions: {
              label: 'Features',
            },
            fieldArray: {
              fieldGroup: [
                {
                  key: 'name',
                  type: 'input',
                  templateOptions: {
                    label: 'Feature Name',
                    required: true,
                  },
                },
              ],
            },
          },

        ],
      },
    ];

    const value: any = await this.formService.show(
      fields,
      this.base + '/' + d.id,
      'PUT',
      Object.assign({}, d),
      {
        title: 'Edit Subscription Plan',
        width: '350px',
      }
    ) as any;

    if (value) {
      await this.init();
    }
  }


  async remove(d: any) {
    const value = await this.formService.confirm();

    if (value) {
      const v: any = await this.api.delete(this.base + '/' + d.id).toPromise();
      if (v.status) {
        await this.init();
      }
    }
  }
}
