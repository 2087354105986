import { Injectable } from '@angular/core';
import { share } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: any = new BehaviorSubject({});
  token = '';
  login_user_Name = '';
  login_user_email = '';

  constructor(private api: ApiService, public http: HttpClient, private router: Router) {
  }

  login(data: any) {
    const seq = this.api.post('auth/login', data).pipe(share());

    seq.subscribe((res: any) => {
      if (res.status) {
        this.router.navigate(['/otp']).then();
        const d = res.data;
        const getOtpdata = JSON.parse(d.otpdata);

        //this.token = 'Bearer ' + d.token;
        localStorage.setItem('token', this.token);

        // localStorage.setItem('login_user_Name', this.login_user_Name);
        // localStorage.setItem('login_user_email', this.login_user_email);

        localStorage.setItem('admin_phone', getOtpdata.data.email_phone);
        localStorage.setItem('admin_hash', getOtpdata.data.hash);
        //this.loggedIn(d.user);
      }
    });

    return seq;
  }

  otplogin(data: any) {
    data['phone'] = localStorage.getItem('admin_phone');
    data['hash'] = localStorage.getItem('admin_hash');
    const seq = this.api.post('auth/otplogin', data).pipe(share());

    seq.subscribe((res: any) => {
      if (res.status) {
        //this.router.navigate(['/otp']).then();

        const d = res.data;
        this.token = 'Bearer ' + d.token;


        this.login_user_Name =  d.user.name;
        this.login_user_email = d.user.email;

        // console.log("name == ", this.login_user_Name);
        // console.log("email == ", this.login_user_email);



        localStorage.setItem('token', this.token);

        localStorage.setItem('login_user_Name', this.login_user_Name);
        localStorage.setItem('login_user_email', this.login_user_email);
        this.loggedIn(d.user);
      }
    });

    return seq;
  }

  forgotPassword(data: any) {
    return this.api.post('auth/forgot-password', data).pipe(share());
  }

  resetPassword(data: any) {
    return this.api.post('auth/reset-password', data).pipe(share());
  }

  async logout() {
    await this.api.get('auth/logout').toPromise();
    this.token = '';
    localStorage.clear();
    await this.user.next({});

    const currentURL = this.router.url;
    const allowURL = ['forgot-password', 'reset-password'];
    if (!allowURL.some(v => currentURL.includes(v))) {
      await this.router.navigate(['/login']);
    }
  }

  loggedIn(res) {
    this.user.next(res);
  }

}


