import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/***####################################### */
// This Servies is for dynamically showing modules based on what sports user is subscribed to
/***####################################### */

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private subscriptionsSubject = new BehaviorSubject<string[]>([]);
  subscriptions$ = this.subscriptionsSubject.asObservable();

  setSubscriptions(subscriptions: string[]) {
    console.log('Setting subscriptions:', subscriptions);
    // this.subscriptions = subscriptions;
    this.subscriptionsSubject.next(subscriptions);
  }


  getSubscriptions(): string[] {
    // return this.subscriptions;
    return this.subscriptionsSubject.getValue();
  }
}
