<div class="row no-gutters">
  <div class="col-auto">
    <div>
      <mat-icon [ngClass]="'icon-'+icon">{{icon}}</mat-icon>
    </div>
  </div>
  <div class="col">
    <div>
      <h2>{{title}}</h2>
      <div [innerHTML]="message"></div>
    </div>
  </div>
</div>
