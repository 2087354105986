<mat-toolbar>
    <mat-icon>redeem</mat-icon>&nbsp;
    <span>Subscriber Payment Detail</span>
    <span class="fill"></span>
    <button (click)="openFilter()" mat-icon-button matTooltip="Filter">
      <mat-icon>filter_list</mat-icon>
    </button>
    <button (click)="exportData()" [disabled]="data.length===0" mat-icon-button matTooltip="Export Data">
      <mat-icon>file_download</mat-icon>
    </button>
  
    <input [(ngModel)]="search" class="search-input" appDelayInput (delayedInput)="init()" placeholder="Search..."
      type="search" />&nbsp;
  </mat-toolbar>
  <div #tt [style.maxHeight]="dataService.getHeight(tt)" class="table-scrollable">
    <table class="table sticky">
      <thead>
        <tr>
          <th>#</th>
          <th>User Name</th>
          <th class="text-right">Amount</th>
          <th class="text-right">Payment Mode</th>
          <th class="text-right">Transaction Id</th>
          <th class="text-right">Payment Date</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let d of data; let index = index">
          <td>{{ d.id }}</td>
          <td>{{ d.user.name }}</td>
          <td class="text-right">{{ d.ammount }}</td>
          <td class="text-right">{{ d.payment_Mode }}</td>
          <td class="text-right">{{ d.transaction_ID }}</td>
          <td class="text-right">{{ d.payment_Date }}</td>
        </tr>
      </tbody>
    </table>
    <mat-paginator (page)="page=$event.pageIndex;perPage=$event.pageSize;init()" [length]="total" [pageIndex]="page"
    [pageSizeOptions]="[15, 25, 50, 100]" [pageSize]="perPage"></mat-paginator>
  </div>
  