// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyBIdIbg1RkbJlVglSUfcwtP2lROqKE2Bqo',
    authDomain: 'mymaster11-3fc38.firebaseapp.com',
    projectId: 'mymaster11-3fc38',
    storageBucket: 'mymaster11-3fc38.appspot.com',
    messagingSenderId: '513074212887',
    appId: '1:513074212887:web:860213228927e832246c52',
    //measurementId: 'G-D1LFX0VE92'
  },
  // url: 'http://127.0.0.1:8000/api/',        // Local host
  // url: 'http://192.168.0.104:8000/api/',
  // url: 'http://65.1.229.25/v2/api/',
  // url: 'https://mmxisecure.mymaster11.com/aapi/api/',        //live Dashboard
  // url: 'http://3.108.229.64/aapi/api/',               //sporstwiz test
  // url: 'http://13.200.43.250/aapi/api/',     //demo test new
  url: 'https://fantasy.dqot.solutions/laravel/api/',
  socket: '/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

// $env:NODE_OPTIONS="--openssl-legacy-provider"; ng serve    for running

// "scripts": {
//   "ng": "ng",
//   "start": "ng serve",
//   "build": "ng build",
//   "prod": "ng build --configuration production --base-href=/admin/",
//   "test": "ng test",
//   "lint": "ng lint",
//   "e2e": "ng e2e"
// },

// npm install --save-dev cross-env --legacy-peer-deps
